<template>
  <i class="las la-asterisk iconMediumRed" />

</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    /* BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    VBTooltip,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText, */
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 10,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPageLocal: this.$props.perPage,
      perPageValues: [2, 10, 20, 100, 200],
    }
  },
  computed: {

  },
  /* watch: {
    currentPage: {
      handler(val, old) {
        // this.emitPaginationOutput()
      },
    },
    perPageLocal: {
      handler(val, old) {
        this.emitPaginationOutput()
      },
    },
    tableData: {
      deep: true,
      handler(val, old) {
        // const largestPerPageValue = [...this.perPageValues].pop()
        // if (this.totalRows > largestPerPageValue) this.perPageValues.push(this.totalRows)
      },
    },
  }, */
  methods: {
    emitPaginationOutput() {
      this.$emit('change', {
        perPage: this.perPageLocal,
        currentPage: this.currentPage,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import "@core/scss/base/bootstrap-extended/include";

ul.pagination.b-pagination {
  margin-bottom: 0;
}
.pagination-ctn {
  @media (max-width: 768px) {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.pagination {
  @media (max-width: 768px) {
    justify-content: center !important;
    width: 100%;
  }
}
</style>
